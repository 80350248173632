import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import qs from "query-string"
import React, { FormEvent, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { BigMessageSpinner } from "../../components/BigMessageSpinner"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"
import { Layout } from "../../components/layout/Layout"
import { LayoutBody } from "../../components/layout/LayoutBody"
import { BareNav } from "../../components/layout/Nav"
import { PasswordInput } from "../../components/auth/PasswordInput"
import { withToastQuery } from "../../components/queryStringToast"
import { ThrowError } from "../../components/ThrowError"
import { getFirebaseApp } from "../../components/auth/FirebaseContext"
import { PageTitle } from "../../components/typing-test/PageTitle"

function PasswordResetForm({
  email,
  actionCode,
}: {
  email: string
  actionCode: string
}) {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const passwordRef = useRef<HTMLInputElement>(null)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    setError("")
    setIsLoading(true)

    if (!passwordRef.current) {
      throw new Error("Expected `passwordRef.current` to be set")
    }

    try {
      await getFirebaseApp()
        .auth()
        .confirmPasswordReset(actionCode, passwordRef.current.value)
      // Redirect to do a full page refresh (since user is logged out now)
      window.location.href = withToastQuery("/account/login", {
        status: "success",
        title: "Success",
        description: "Your new account password has been set.",
        isClosable: true,
      })
    } catch (err) {
      passwordRef.current.value = ""
      setError(err.message)
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input value={email} readOnly disabled size="lg" />
        </FormControl>

        <FormControl id="password">
          <FormLabel>New password</FormLabel>
          <PasswordInput
            autoFocus
            inputRef={passwordRef}
            isRequired
            isNewPassword
            size="lg"
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="green"
          size="lg"
          isLoading={isLoading}
        >
          Save
        </Button>
      </FormVStack>
    </form>
  )
}

export default function PasswordReset() {
  const [state, setState] = useState<{
    email: string
    actionCode: string
  } | null>(null)
  const title = "🗝 Create Password"

  useEffect(() => {
    const { email, oobCode } = qs.parse(window.location.search)
    if (typeof email !== "string") {
      throw new Error("Expected email to be a string")
    }
    if (typeof oobCode !== "string") {
      throw new Error("Expected oobCode to be a string")
    }
    setState({ email, actionCode: oobCode })
  }, [])

  // Loading because default state value is `null`
  if (!state) {
    return (
      <Layout>
        <PageTitle>{title}</PageTitle>
        <BareNav />
        <LayoutBody>
          <BigMessageSpinner message="Loading..." />
        </LayoutBody>
      </Layout>
    )
  }

  // If state is defined but items aren't there
  if (!state.email || !state.actionCode) {
    return (
      <Layout>
        <PageTitle>{title}</PageTitle>
        <BareNav />
        <LayoutBody>
          <ThrowError
            error={new Error("Missing `email` and/or `actionCode`.")}
          />
        </LayoutBody>
      </Layout>
    )
  }

  // state is loaded!
  return (
    <FormLayout title={title}>
      <PasswordResetForm email={state.email} actionCode={state.actionCode} />
    </FormLayout>
  )
}
